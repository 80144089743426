<template>
  <div class="row">

    <!-- Table -->
    <div class="col-md-8">
      <Card :title="'Histori Pembayaran'">
        <!-- Header -->
        <template v-slot:toolbar>
          <b-button
            squared
            variant="success"
            class="mx-2"
            @click="$router.push(`/payment/add?patient_id=${patient.id}&patient_name=${patient.name} (${patient.mobile_phone})`)"
          ><i class="fas fa-fw fa-plus"></i> Pembayaran</b-button>
        </template>
        <template v-slot:body>
          <Table
            :fields="fields"
            :items="items"
            :hasPatient="true"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            @pageOnClick="pageOnClick"
            @filter="generalFilter"
          />
        </template>
      </Card>
    </div>

    <div class="col-md-4">
      <!-- Profile -->
      <Card
        :title="patient.name"
        :class="'mb-3'"
      >
        <template v-slot:body>
          <div class="d-flex align-items-start mb-2">
            <span class="mr-2 text-hover-success"><i class="fas fa-fw fa-user"></i></span>
            <span class="text-hover-success font-weight-bold">{{ patient.name }}</span>
          </div>
          <div class="d-flex align-items-start mb-2">
            <span class="mr-2 text-hover-success"><i class="fas fa-fw fa-envelope"></i></span>
            <span class="text-hover-success font-weight-bold">{{ patient.email }}</span>
          </div>
          <div class="d-flex align-items-start mb-2">
            <span class="mr-2 text-hover-success"><i class="fas fa-fw fa-phone"></i></span>
            <span class="text-hover-success font-weight-bold">{{ patient.mobile_phone }}</span>
          </div>
          <div class="d-flex align-items-start mb-2">
            <span class="mr-2 text-hover-success"><i class="fas fa-fw fa-home"></i></span>
            <span class="text-hover-success font-weight-bold">{{ patient.address }}</span>
          </div>
        </template>
      </Card>

      <h3>Sisa Pembayaran</h3>

      <div style="max-height: 280px; overflow-y:auto">
        <!-- Open Payment -->
        <div
          class="my-3 card-open-payment card"
          v-for="payment in openPayments"
          :key="payment.id"
          v-b-tooltip.hover
          title="Lihat Detail"
          @click="$router.push(`/payment/detail/${payment.id}`)"
        >
          <div class="p-5">
            <div class="d-flex align-items-center justify-content-between py-0">
              <div class="text-hover-success">Tanggal</div>
              <div class="text-hover-success">{{ payment.display_created_at }}</div>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between py-0">
            <div class="text-hover-success">Subtotal</div>
            <div class="text-hover-success">Rp {{ parseInt(payment.total_amount).toLocaleString('id-ID') }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-between py-0">
            <div class="text-hover-success">Biaya Tambahan</div>
            <div class="text-hover-success">Rp {{ parseInt(payment.surcharge).toLocaleString('id-ID') }}</div>
          </div> -->
            <div class="d-flex align-items-center justify-content-between py-0">
              <div class="h6 text-hover-success">Total Tagihan</div>
              <div class="h6 font-weight-bold text-hover-success"><strong>Rp {{ parseInt(payment.final_amount).toLocaleString('id-ID') }}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Card from '@/view/content/Card.vue'
import Table from '@/component/payments/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      // Patient Data
      patient: {},
      // Filter
      filter: {
        start_date: '',
        end_date: '',
        doctor_id: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Tagihan",
          sortable: true,
        },
        {
          key: "payment_status_id",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Open Payment
      openPayments: []
    }
  },

  methods: {

    async getPatient() {
      this.patient = await module.get('patients/' + this.$route.params.id)
      if (this.patient == null) {
        this.$router.push('/patient/list')
      }
    },

    async pagination() {
      const PAID = 3
      let filterParams = `&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`payments/get-by-patient/${this.$route.params.id}`, `?payment_status=${PAID}&page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getOpenPayment() {
      const OPEN = 1
      // let filterParams = `&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`payments/get-by-patient/${this.$route.params.id}`, `?payment_status=${OPEN}&page=${this.currentPage}&page_size=${this.perPage}`)
      this.openPayments = response.data
    },

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    generalFilter(sended) {
      this.filter.doctor_id = sended.doctor_id
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.pagination()
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Pembayaran" },
    ])
    this.getPatient()
    this.getOpenPayment()
    this.pagination()
  },

}
</script>

<style scoped>
.card-open-payment:hover {
  box-shadow: 2px 2px 2px rgba(175, 175, 175, 0.527) !important;
  cursor: pointer;
}
</style>